@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

/* .slider {
  position: relative;
  background: white;
  box-shadow: 0 10px 20px -10px rgba (0, 0, 0, 0.2);
  overflow: hidden;
}
.slide {
  display: flex;
  align-items: center;
  animation: slideshow 15s linear infinite;
}

@keyframes slideshow {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100%);
  }
}

.slider::before,
.slider::after {
  height: 100px;
  width: 200px;
  position: absolute;
  content: "";
  background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
  z-index: 2;
}
.slider::before {
  left: 0;
  top: 0;
}
.slider::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
}

.ss {
  position: -webkit-sticky;
} */

.wrapper {
  position: relative;
  /* display: flex; */
  overflow: hidden;
}

.wrapper .item {
  /* display: flex; */
}

.wrapper:hover .item {
  animation-play-state: paused;
}

/* .wrapper::before,
.wrapper::after {
  height: 100px;
  width: 200px;
  position: absolute;
  content: "";
  background: linear-gradient(to right, white 0%, rgba(255, 255, 255, 0) 100%);
  z-index: 2;
} */

/* .wrapper::before {
  left: 0;
  top: 0;
}
.wrapper::after {
  right: 0;
  top: 0;
  transform: rotateZ(180deg);
} */
/* 
@keyframes animate {
  0% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(-100px);
  }
} */

@media only screen and (max-width: 600px) {
  /* .wrapper::before,
  .wrapper::after {
    height: 100px;
    width: 25px;
    position: absolute;
    content: "";
    background: linear-gradient(
      to right,
      white 0%,
      rgba(255, 255, 255, 0) 100%
    );
    z-index: 2;
  }

  .wrapper::before {
    left: 0;
    top: 0;
  }
  .wrapper::after {
    right: 0;
    top: 0;
    transform: rotateZ(100deg);
  } */
}

/* @media only screen and (max-width: 600px) {
  .item {
    width: 200px;
  }
}

@media only screen and (min-width: 600px) {
  .item {
    width: 200px;
  }
}

@media only screen and (min-width: 768px) {
  .item {
    width: 200px;
  }
}

@media only screen and (min-width: 992px) {
  .item {
    width: 200px;
  }
} */

